/*
    Due to "npm run start" will access backend api through api gateway,
    so we need to add "/aidp" to the api base url when not in dev environment
*/
export const API_BASE_URL = `${process.env.REACT_APP_APIGATEWAY_URL}${!process.env.REACT_APP_IS_DEV ? '/aidp' : ''}`;

// you can also use wss directly in dev environment, but it will connect to develop backend server not localhost
export const WS_BASE_URL = process.env.REACT_APP_IS_DEV ? 'ws://localhost:12090/api/ctrag/ws/chat'
// development
: process.env.REACT_APP_ENV === 'development' ? 'wss://5s6sqe3yfh.execute-api.ap-northeast-1.amazonaws.com/api/'
// production
: 'wss://zd5jfiozvk.execute-api.ap-northeast-1.amazonaws.com/api/';
export const IS_DEV = process.env.REACT_APP_IS_DEV;
