import React, { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Card } from 'antd';
import { FolderOutlined, CommentOutlined, ContainerOutlined, ToolOutlined, BookOutlined } from '@ant-design/icons';
import AuthContext from '../../contexts/authProvider';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const { Content } = Layout;
const SelectMenu = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  if (!user) return <Navigate to="/login" />;

  return (
    <Layout className="app-layout">
      <Header />
      <Content className="app-layout-content" style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100%' }}>
          <Row
            justify="center"
            align="middle"
            gutter={[
              { xs: 16, sm: 16, md: 16, lg: 16 },
              { xs: 16, sm: 16, md: 16, lg: 16 },
            ]}
          >
            <Col xs={12} sm={12} md={12} lg={4} xl={3}>
              <Card className="app-select-menu-item">
                <div className="app-select-menu-wrap" onClick={() => navigate('/')}>
                  <div className="icon">
                    <FolderOutlined />
                  </div>
                  <div className="description">檔案瀏覽與搜尋</div>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={3}>
              <Card className="app-select-menu-item">
                <div className="app-select-menu-wrap" onClick={() => navigate('/askquestion')}>
                  <div className="icon">
                    <CommentOutlined />
                  </div>
                  <div className="description">微電知識問答</div>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={3}>
              <Card className="app-select-menu-item" onClick={() => navigate('/knowledgeBaseManager')}>
                <div className="app-select-menu-wrap">
                  <div className="icon">
                    <BookOutlined />
                  </div>
                  <div className="description">知識庫管理</div>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={3}>
              <Card className="app-select-menu-item disabled">
                <div className="app-select-menu-wrap">
                  <div className="icon">
                    <ContainerOutlined />
                  </div>
                  <div className="description">專案資訊管理</div>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={3}>
              <Card className="app-select-menu-item disabled">
                <div className="app-select-menu-wrap">
                  <div className="icon">
                    <ToolOutlined />
                  </div>
                  <div className="description">實用工具箱</div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default SelectMenu;
