import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Menu, Typography, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  StarOutlined,
  DeleteOutlined,
  HomeOutlined,
  FileTextOutlined,
  HistoryOutlined,
  MessageOutlined,
  MenuUnfoldOutlined,
  BookOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import useQueryParams from '../../hooks/useQueryParams';
import { useChatHistory } from '../../hooks/useChatHistory';
import { Link as RouterLink } from 'react-router-dom';
import { useKnowledgeBase } from '../../contexts/knowledgeBaseContext';

const { Link } = Typography;
const { Sider } = Layout;

function getItem(label, key, icon, children, disabled, type) {
  return {
    key,
    icon,
    children,
    label,
    disabled,
    type,
  };
}

const fileManagerMenuItems = [
  getItem(<Link href="/filemanager">微電能源</Link>, 'filemanager', <HomeOutlined />, null),
  getItem(
    <Link href="/filemanager?conditions[0][field]=starred&conditions[0][operation]=boolean&conditions[0][value]=true&conditions[1][field]=trashed&conditions[1][operation]=boolean&conditions[1][value]=false">
      已加星號
    </Link>,
    'starred',
    <StarOutlined />,
    null
  ),
  getItem(
    <Link href="/filemanager?conditions[0][field]=trashed&conditions[0][operation]=boolean&conditions[0][value]=true">
      垃圾桶
    </Link>,
    'trashed',
    <DeleteOutlined />,
    null
  ),
];

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const queryParams = useQueryParams();
  const location = useLocation();
  
  const isAskQuestion = location.pathname.startsWith('/askquestion');
  const { chatHistory, loadMore, hasMore } = useChatHistory(isAskQuestion);
  const { knowledgeBases, fetchKnowledgeBases } = useKnowledgeBase();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setIsMobile(true);
        setHidden(true);
      } else {
        setIsMobile(false);
        setHidden(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/knowledgeBaseManager') && knowledgeBases.length === 0) {
      fetchKnowledgeBases();
    }
  }, [location.pathname, fetchKnowledgeBases, knowledgeBases.length]);

  const askQuestionMenuItems = useMemo(() => {
    const uniqueChats = [];
    const seenIds = new Set();

    chatHistory.forEach(chat => {
      if (!seenIds.has(chat.chat_session_id)) {
        seenIds.add(chat.chat_session_id);
        uniqueChats.push(
          getItem(
            <RouterLink to={`/askquestion/chat/${chat.chat_session_id}`}>{chat.chat_title}</RouterLink>,
            `chat-${chat.chat_session_id}`,
            <MessageOutlined />,
            null
          )
        );
      }
    });

    if (hasMore) {
      uniqueChats.push(getItem('顯示更多', 'showMore', null, null, false, 'submenu'));
    }

    return [
      getItem(<Link href="/askquestion">開始新的聊天</Link>, 'allQuestions', <FileTextOutlined />, null),
      getItem(
        '歷史聊天',
        'chatHistory',
        <HistoryOutlined />,
        uniqueChats,
        false,
        'group'
      ),
    ];
  }, [chatHistory, hasMore]);

  const knowledgeBaseManagerMenuItems = useMemo(() => {
    const subMenuItems = knowledgeBases.map(kb => 
      getItem(
        <RouterLink to={`/knowledgeBaseManager/${kb.knowledge_base_name}`}>
          {kb.knowledge_base_name}
        </RouterLink>,
        `kb-${kb.knowledge_base_name}`,
        <FolderOutlined />,
        null
      )
    );

    return [
      getItem(<Link href="/knowledgeBaseManager">新增知識庫</Link>, 'knowledgeBaseManager', <BookOutlined />, null),
      getItem(
        '已建立的知識庫',
        'knowledgeBaseList',
        <BookOutlined />,
        subMenuItems,
        false,
        'group'
      ),
    ];
  }, [knowledgeBases]);

  const handleMenuClick = ({ key }) => {
    if (key === 'showMore') {
      loadMore();
    }
  };

  const menuItems = useMemo(() => {
    if (location.pathname.startsWith('/askquestion')) {
      return askQuestionMenuItems;
    }
    if (location.pathname.startsWith('/knowledgeBaseManager')) {
      return knowledgeBaseManagerMenuItems;
    }
    return fileManagerMenuItems;
  }, [location.pathname, askQuestionMenuItems, knowledgeBaseManagerMenuItems]);

  const selectedKeys = useMemo(() => {
    if (location.pathname.startsWith('/askquestion')) {
      const pathParts = location.pathname.split('/');
      if (pathParts[2] === 'chat') {
        return [`chat-${pathParts[3]}`];
      }
      return [pathParts[2] || 'allQuestions'];
    }
    if (location.pathname.startsWith('/knowledgeBaseManager')) {
      const pathParts = location.pathname.split('/');
      if (pathParts.length > 2 && pathParts[2] !== '') {
        return [`kb-${decodeURIComponent(pathParts[2])}`];
      }
      return ['knowledgeBaseManager'];
    }
    const item = queryParams?.find?.(
      (item) => item.field === 'starred' || (item.field === 'trashed' && item.value === true)
    );
    return item ? [item.field] : ['filemanager'];
  }, [queryParams, location.pathname]);

  const toggleSidebar = () => {
    if (isMobile) {
      setHidden(!hidden);
    }
  };

  return (
    <>
      {isMobile && hidden && (
        <Button
          className="sidebar-toggle"
          onClick={toggleSidebar}
          icon={<MenuUnfoldOutlined />}
        />
      )}
      <Sider
        collapsible
        className={`app-aside ${isMobile && hidden ? 'hidden' : ''}`}
        collapsed={collapsed}
        collapsedWidth={60}
        onCollapse={(value) => setCollapsed(value)}
        trigger={collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
      >
        <Menu
          mode="inline"
          className="app-aside-menu"
          inlineIndent={16}
          selectedKeys={selectedKeys}
          items={menuItems}
          onClick={handleMenuClick}
        />
      </Sider>
      {isMobile && !hidden && (
        <div className="sidebar-overlay" onClick={toggleSidebar}></div>
      )}
    </>
  );
};

export default Sidebar;
