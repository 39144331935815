import React, { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Badge, Layout, Dropdown } from 'antd';
import {
  SettingOutlined,
  PoweroffOutlined,
  MenuOutlined,
  FolderOutlined,
  ToolOutlined,
  ContainerOutlined,
  CommentOutlined,
  BookOutlined,
} from '@ant-design/icons';
import AuthContext from '../../contexts/authProvider';
import logo_icon from '../../images/logo_icon.svg';

const { Header } = Layout;
const menuItems = [
  {
    key: 'fileSearch',
    label: <Link to="/">檔案瀏覽與搜尋</Link>,
    icon: <FolderOutlined />,
    name: '檔案瀏覽與搜尋',
    path: '/',
  },
  {
    key: 'manager',
    label: <Link to="/projects">專案資訊管理</Link>,
    icon: <ContainerOutlined />,
    name: '專案資訊管理',
    path: '/',
    disabled: true,
  },
  {
    key: 'askQuestion',
    label: <Link to="/askquestion">微電知識問答</Link>,
    icon: <CommentOutlined />,
    name: '微電知識問答',
    path: '/askquestion',
  },
  {
    key: 'knowledgeBaseManager',
    label: <Link to="/knowledgeBaseManager">知識庫管理</Link>,
    icon: <BookOutlined />,
    name: '知識庫管理', 
    path: '/knowledgeBaseManager',
  },
  {
    key: 'tool',
    label: <Link to="/">實用工具箱</Link>,
    icon: <ToolOutlined />,
    name: '實用工具箱',
    path: '/',
    disabled: true,
  },
];

const AppHeader = () => {
  const { user, UnAuthUser } = useContext(AuthContext);
  const { pathname } = useLocation();

  const userItems = useMemo(
    () => [
      {
        key: 'environment',
        label: <Link to="/settings">環境設定</Link>,
        icon: (
          <Link to="/settings">
            <SettingOutlined />
          </Link>
        ),
        name: '環境設定',
        path: '/settings',
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: <span onClick={() => UnAuthUser()}>登出</span>,
        icon: (
          <span onClick={() => UnAuthUser()}>
            <PoweroffOutlined />
          </span>
        ),
        name: '登出',
        path: '',
      },
    ],
    [UnAuthUser]
  );

  return (
    <Header className="app-header">
      <div className="app-header-container">
        <div className="app-header-left">
          <div className="app-header-menu">
            <Dropdown placement="bottomLeft" menu={{ items: menuItems }} trigger={['click']}>
              <MenuOutlined style={{ padding: '20px 4px', display: 'inline-block' }} />
            </Dropdown>
          </div>
          <div className="app-header-logo">
            <Link to="/">
              <img src={logo_icon} alt="微電能源-能源管理系統" />
            </Link>
            <span className="app-header-logo-text">
              {pathname === '/projects' ? '專案資訊管理' : 
               pathname === '/filemanager' ? '檔案瀏覽與查詢' :
               pathname === '/askquestion' ? '微電知識問答' :
               pathname === '/knowledgeBaseManager' ? '知識庫管理' :
               pathname === '/settings' ? '環境設定' : '檔案瀏覽與查詢'}
            </span>
          </div>
        </div>

        <ul className="app-header-right">
          <li>
            <div className="user">
              <Dropdown placement="bottom" menu={{ items: userItems }} trigger={['click']}>
                <div>
                  <Badge size="small" count={0}>
                    <Avatar size="small" src={user?.picture} />
                  </Badge>
                  <span className="name">{user?.given_name}</span>
                </div>
              </Dropdown>
            </div>
          </li>
        </ul>
      </div>
    </Header>
  );
};

export default AppHeader;
